<template>
<div>
  <v-alert type="success" v-if="sent">
    <strong>{{ csuccessText }}</strong>
  </v-alert>

  <v-alert type="error" v-if="error">
    <strong>{{ $t('Bitte prüfen Sie Ihre Eingaben.') }}</strong>
  </v-alert>

  <div v-if="!sent">
    <slot></slot>
    <v-form class="contact-form" @submit="submit">
        <!--
        :error="error && !disclaimerCheckbox"
        :rules="[() => !!disclaimerCheckbox || 'Pflichtfeld']"
        -->

      <div class="form-group contact__disclaimer">
        <v-checkbox hide-details v-model="disclaimerCheckbox" :error="!disclaimerCheckbox && error" off-icon="fas fa-square" on-icon="fas fa-check-square">
          <template v-slot:label>
            <div class="text-xsmall" v-if="disclaimerSlot">
                <slot name="disclaimer"></slot>
                <a :href="cprivacyUrl" target="_blank" @click.stop>Datenschutzerklärung und Widerrufshinweise</a>
            </div>
            <div class="text-xsmall" v-else>
                {{ $t('Ich habe die') }} <a :href="cprivacyUrl" target="_blank" @click.stop>{{ $t('Datenschutzerklärung') }}</a> {{ $t('zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden. Hinweis: Sie können Ihre Einwilligung jederzeit widerrufen.') }}
            </div>
          </template>
        </v-checkbox>
      </div>

      <div :class="buttonWrapperClass">
      <button
          :class="buttonClass"
          :disabled="loading"
          v-show="!sent"
          type="submit"
          @click="submit">{{ cbuttonText }}</button>
      </div>
    </v-form>
  </div>
</div>
</template>

<script>
import depsloader from "../../depsloader";

export default {
  name: "ContactForm",

  components: {
  },

  props: {
    name: String,
    requiredFields: Array,
    privacyUrl: String,
    submitUrl: String,
    authenticationToken: String,
    propImmoExpose: Array,
    propInquiry: Object,
    buttonText: {
      default: "Nachricht senden",
      type: String
    },
    buttonWrapperClass: {
      default: "text-left",
      type: String
    },
    buttonClass: {
      default: "btn btn-primary btn-std",
      type: String
    },
    successText: String
  },

  data() {
    return {
      valid: false,
      loading: false,
      contact: {
        company: null,
        salutation: null,
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        message: null,
        contactType: null,
        contactReason: null
      },
      dataFields: {},
      inquiry: null,
      immoExposeId: null,
      immoExposeCid: null,
      sent: false,
      error: false,
      errors: [],
      contactFields: [],
      disclaimerCheckbox: false,
      fileUploads: []
    };
  },

  computed: {
    disclaimerSlot() {
      return "disclaimer" in this.$slots;
    },

    cprivacyUrl() {
      if (this.privacyUrl) {
        return this.privacyUrl;
      }

      return this.$yeti.privacyUrl();
    },

    csuccessText() {
      if (this.successText) {
        return this.successText;
      }

      return this.$t("Ihre Nachricht wurde erfolgreich verschickt.");
    },

    cbuttonText() {
      return this.$t(this.buttonText);
    }
  },

  created() {
    this.$root.contactForm = this;

    if (this.propImmoExpose) {
      this.immoExposeId = this.propImmoExpose[0];
      this.immoExposeCid = this.propImmoExpose[1];
    }
  },

  methods: {
    submit(e) {
      e.preventDefault();

      this.contactFields.forEach((field) => {
        field.mapValue();
      });

      const data = {
        form: this.name,
        contact: this.contact,
        data: this.dataFields
      };

      if (this.propInquiry != null) {
        data.inquiry = this.propInquiry;
      }

      if (this.inquiry != null) {
        data.inquiry = this.inquiry;
      }

      if (this.immoExposeId != null) {
        data.immoExposeId = this.immoExposeId;
      }

      if (!this.disclaimerCheckbox) {
        this.error = true;
        return;
      }

      const fileUpload = this.fileUploads.length > 0;
      let axiosConfig = null;
      let axiosData = null;

      if (fileUpload) {
        // file upload
        axiosConfig = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };
        axiosData = new FormData();

        axiosData.append("json", JSON.stringify(data));

        for (let i = 0; i < this.fileUploads.length; i++) {
          if (this.fileUploads[i].files.length > 0) {
            for (let j = 0; j < this.fileUploads[i].files.length; j++) {
              axiosData.append("files[]", this.fileUploads[i].files[j]);
            }
          }
        }
      } else {
        axiosData = data;
      }

      this.loading = true;
      if (this.$store) {
        this.$store.commit("loading", true);
      }

      let url = this.submitUrl;
      if (!url) {
        url = "/wp-json/sw-immo/v1/contact";
      }

      depsloader.load("axios").then((axios) => {
        const headers = {}

        if (this.authenticationToken) {
          headers.Authorization = `Bearer ${this.authenticationToken}`;
        }

        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let subject = params.get("subject");
        if (subject) {
          axiosData["subject"] = subject;
        }

        axios({
          method: "post",
          url: url,
          headers: headers,
          data: axiosData,
          config: axiosConfig
        }).then((res) => {
          this.error = false;
          if ("error" in res.data && res.data.error) {
            this.error = true;
          }

          this.errors = [];
          if ("errors" in res.data) {
            this.errors = res.data.errors;
          }

          this.sent = !this.error;

          if (this.sent) {
            this.$emit("sent");

            axios({
              method: "put",
              url: `/wp-json/sw-immo/v1/contact-sent?name=${encodeURIComponent(this.name)}`
            });

            if (this.immoExposeCid != null) {
              this.$yeti.trackEvent("immo", "object-inquiry", this.immoExposeCid);
            } else if (url == "/wp-json/sw-immoapp/v1/contact/lead") {
              this.$yeti.trackEvent("conversion", "lead");
            } else if (url == "/wp-json/sw-immoapp/v1/contact/saved-search") {
              this.$yeti.trackEvent("immo", "prospect-search-inquiry");
            } else {
              this.$yeti.trackEvent("conversion", "contact");
            }
          }
        }).catch((e) => {
          console.log(e);
          this.error = true;
        }).finally(() => {
          this.loading = false;
          if (this.$store) {
            this.$store.commit("loading", false);
          }
        });
      });
    },

    isRequired(field) {
      return this.requiredFields.indexOf(field) > -1;
    },

    registerField(field) {
      this.contactFields.push(field);
    },

    unregisterField(field) {
      const list = [];
      this.contactFields.forEach((f) => {
        if (f !== field) {
          list.push(f);
        }
      });
      this.contactFields = list;
    },

    addFileUploadElement(elm) {
      this.fileUploads.push(elm);
    }
  }
};
</script>
