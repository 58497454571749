import deps from "../depsloader";

export default () => {
  const elm = document.querySelector(".sidebanner");
  if (!elm) {
    return;
  }

  const btn = elm.querySelector(".close-btn");
  if (!btn) {
    return;
  }

  deps.load("js-cookie").then((Cookies) => {
    btn.addEventListener("click", () => {
      elm.classList.add("closed");

      Cookies.set("sidebanner", 1, { expires: 1 });
    });

    const cookie = Cookies.get("sidebanner");
    if (!cookie) {
      elm.classList.remove("closed");
    }
  });
}
