import Vue from "vue";
import vuetify from "./vuetify.js";
import deps from "./deps";
import yeti from "./yeti";
import MainCss from "./MainCss";

import "./sass/fontawesome/fas.font.js"
import "./sass/fontawesome/far.font.js"
import "./sass/fontawesome/fal.font.js"
import "./sass/fontawesome/fab.font.js"
import "./sass/fontawesome/fontawesome.scss";

import "@fontsource/hind/300.css";
import "@fontsource/hind/400.css";
import "@fontsource/hind/500.css";
import "@fontsource/hind/600.css";
import "@fontsource/hind/700.css";

import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

Vue.config.productionTip = false;

Vue.prototype.$yeti = yeti;
Vue.prototype.$yeti.setupTracking();

// lädt das CSS in der richtigen Reihenfolge
new Vue({
  vuetify,
  components: {
    MainCss
  }
});

Object.keys(deps.entrypoints).forEach(entrypoint => {
  const fn = require("./entrypoints/" + entrypoint + ".js");
  fn.default();
});

const requirePO = require.context('./lang', false, /\.po$/);
requirePO.keys().forEach(requirePO);
