<template>
<v-app>
 <v-dialog
      v-model="dialog"
      width="566"
    >
    <v-card>
      <v-card-title
        class="headline bg-primary w-100"
        primary-title
      >
          <p class="h3 mb-0">{{ title }}</p>

          <v-spacer></v-spacer>
          <div
            class="float-right"><v-btn
            icon
            dark
            @click="dialog = false">
            <span class="fal fa-2x fa-times"></span>
          </v-btn>
          </div>
      </v-card-title>

      <v-card-text>
        <div v-html="content"></div>
      </v-card-text>
    </v-card>
 </v-dialog>
</v-app>
</template>

<script>
export default {
  name: "Modal",

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    id() {
      return this.$root.id;
    },

    title() {
      return this.$root.title;
    },

    content() {
      return this.$root.content;
    }
  },

  mounted() {
    const openButtons = document.querySelectorAll("a[href='#" + this.id + "']");
    if (openButtons) {
      for (const btn of openButtons) {
        btn.addEventListener("click", (e) => {
          e.preventDefault();

          this.dialog = true;
        });
      }
    }
  }
}
</script>
